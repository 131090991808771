define("discourse/plugins/chat/discourse/components/thread-title/index", ["exports", "@glimmer/component", "@ember/routing", "@ember/template", "discourse/helpers/replace-emoji", "discourse/lib/utilities", "discourse/plugins/chat/discourse/components/thread-unread-indicator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _template2, _replaceEmoji, _utilities, _threadUnreadIndicator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadTitle extends _component.default {
    get title() {
      if (this.args.thread.title) {
        return (0, _replaceEmoji.default)((0, _template2.htmlSafe)((0, _utilities.escapeExpression)(this.args.thread.title)));
      } else {
        return (0, _replaceEmoji.default)((0, _template2.htmlSafe)(this.args.thread.originalMessage.excerpt));
      }
    }
    static #_ = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat__thread-title-container">
          <div class="chat__thread-title">
            <LinkTo
              class="chat__thread-title__name"
              @route="chat.channel.thread"
              @models={{@thread.routeModels}}
            >
              {{this.title}}
            </LinkTo>
    
            <ThreadUnreadIndicator @thread={{@thread}} />
          </div>
        </div>
      
    */
    {
      "id": "buLLULLV",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat__thread-title-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat__thread-title\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"chat__thread-title__name\"]],[[\"@route\",\"@models\"],[\"chat.channel.thread\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"title\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[32,1],null,[[\"@thread\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@thread\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/thread-title/index.js",
      "scope": () => [_routing.LinkTo, _threadUnreadIndicator.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatThreadTitle;
});